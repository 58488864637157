import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { DateRange } from "react-date-range";
import { startOfMonth, endOfMonth, isAfter, isBefore, startOfDay } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Sidebar from "../components/Sidebar";

const ExportLeads = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isFocusDate, setIsFocusDate] = useState(false);
  const [totalLeads, setTotalLeads] = useState(0);
  const [totalProcessed, setTotalProcessed] = useState(0);
  const [totalSkipped, setTotalSkipped] = useState(0);
  const [isCustomDateRange, setIsCustomDateRange] = useState(false);
  const [hasExported, setHasExported] = useState(false);
  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(true);
  const [exportError, setExportError] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [canExport, setCanExport] = useState(false);

  const today = startOfDay(new Date());
  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfMonth(today),
      endDate: isBefore(endOfMonth(today), today) ? endOfMonth(today) : today,
      key: "selection",
    },
  ]);

  const fetchCampaigns = useCallback(async (range) => {
    setIsLoadingCampaigns(true);
    setExportError(null);
    try {
      const startDate = range.startDate.toISOString().split("T")[0];
      const endDate = range.endDate.toISOString().split("T")[0];
      
      const response = await axios.get(
        `/api/campaigns/get_campaigns.php?startDate=${startDate}&endDate=${endDate}&t=${new Date().getTime()}&api_key=mangoCia_2024_APIKEY_8f6a7d9e4c3b2a1`
      );
      
      if (response.data && Array.isArray(response.data.campaigns)) {
        const sortedCampaigns = response.data.campaigns.sort((a, b) => b.totalLeads - a.totalLeads);
        setCampaigns(sortedCampaigns);
      }
    } catch (error) {
      console.error("Error al cargar campañas:", error);
      setExportError("Error al cargar las campañas. Por favor, intente nuevamente.");
    } finally {
      setIsLoadingCampaigns(false);
    }
  }, []);

  useEffect(() => {
    fetchCampaigns(dateRange[0]);
  }, [fetchCampaigns]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dateRangePicker = document.querySelector(".date-range-picker");
      if (dateRangePicker && !dateRangePicker.contains(event.target)) {
        setIsFocusDate(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const previewLeads = async () => {
    setIsLoadingPreview(true);
    setExportError(null);
    setPreviewData(null);
    setCanExport(false);
    
    try {
      const startDate = dateRange[0].startDate.toISOString().split("T")[0];
      const endDate = dateRange[0].endDate.toISOString().split("T")[0];
      const encodedCampaign = encodeURIComponent(selectedCampaign);

      const response = await axios.get(
        `/api/leads/preview_leads_count.php?campaign=${encodedCampaign}&startDate=${startDate}&endDate=${endDate}&api_key=mangoCia_2024_APIKEY_8f6a7d9e4c3b2a1`
      );

      if (response.data.error) {
        throw new Error(response.data.message);
      }

      setPreviewData(response.data.data);
      setCanExport(true);
      setHasExported(false);
    } catch (error) {
      console.error("Error en previsualización:", error);
      setExportError(error.response?.data?.message || "Error al previsualizar leads");
      setCanExport(false);
    } finally {
      setIsLoadingPreview(false);
    }
  };

  const handleDownload = (response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `leads_${selectedCampaign}_${new Date().toISOString().split('T')[0]}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const exportToCSV = async () => {
    if (!canExport) return;
    
    setIsLoading(true);
    setExportError(null);
    try {
      const startDate = dateRange[0].startDate.toISOString().split("T")[0];
      const endDate = dateRange[0].endDate.toISOString().split("T")[0];
      const encodedCampaign = encodeURIComponent(selectedCampaign);

      const response = await axios.get(
        `/api/leads/export_leads_contacts.php?campaign=${encodedCampaign}&startDate=${startDate}&endDate=${endDate}&api_key=mangoCia_2024_APIKEY_8f6a7d9e4c3b2a1`,
        {
          responseType: 'blob',
          headers: {
            'Accept': 'text/csv,application/json',
            'Content-Type': 'application/json'
          }
        }
      );

      const contentType = response.headers['content-type'];
      
      if (contentType && contentType.includes('application/json')) {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const errorResponse = JSON.parse(reader.result);
            setExportError(errorResponse.message || "Error al exportar los leads");
          } catch (e) {
            console.error('Error al parsear respuesta:', e);
            setExportError("Error desconocido al procesar la respuesta");
          }
        };
        reader.readAsText(response.data);
      } else {
        handleDownload(response);

        const leads = parseInt(response.headers['x-total-leads']) || 0;
        const processed = parseInt(response.headers['x-processed']) || 0;
        const skipped = parseInt(response.headers['x-skipped']) || 0;

        setTotalLeads(leads);
        setTotalProcessed(processed);
        setTotalSkipped(skipped);
        setHasExported(true);
        setPreviewData(null);
      }

    } catch (error) {
      console.error("Error detallado:", error);
      let errorMessage = "Error al exportar los leads.";
      
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      } else if (error.response?.status === 401) {
        errorMessage = "Error de autenticación. Por favor, verifique sus credenciales.";
      } else if (error.response?.status === 500) {
        errorMessage = `Error en el servidor. ${error.response?.data?.message || ''}`;
      }
      
      setExportError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExport = (e) => {
    e.preventDefault();
    exportToCSV();
  };

  const handleDateRangeChange = (item) => {
    const newRange = item.selection;
    if (isAfter(newRange.endDate, today)) {
      newRange.endDate = today;
    }
    setDateRange([newRange]);
    setIsCustomDateRange(true);
    setCanExport(false);
    setPreviewData(null);
    fetchCampaigns(newRange);
  };

  const resetToCurrentMonth = () => {
    const currentMonthRange = {
      startDate: startOfMonth(today),
      endDate: isBefore(endOfMonth(today), today) ? endOfMonth(today) : today,
      key: "selection",
    };
    setDateRange([currentMonthRange]);
    setIsCustomDateRange(false);
    setCanExport(false);
    setPreviewData(null);
    fetchCampaigns(currentMonthRange);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const renderCampaignSelector = () => {
    if (isLoadingCampaigns) {
      return (
        <div className="selector">
          <select disabled className="bg-gray-100">
            <option>Cargando campañas...</option>
          </select>
          <div className="selectArrowForm"></div>
        </div>
      );
    }

    return (
      <div className="selector">
        <select
          value={selectedCampaign}
          onChange={(e) => {
            setSelectedCampaign(e.target.value);
            setCanExport(false);
            setPreviewData(null);
          }}
          className="w-full p-2 border rounded"
        >
          <option value="all">Todas las campañas</option>
          {campaigns.map((campaign) => (
            <option key={campaign.id} value={campaign.nombre}>
              {campaign.nombre} ({campaign.totalLeads} leads)
            </option>
          ))}
        </select>
        <div className="selectArrowForm"></div>
      </div>
    );
  };

  return (
    <div className="app-container">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className={`main-content ${isSidebarOpen ? "sidebar-open" : ""}`}>
        <div className="search-leads-container">
          <h1 className="text-2xl font-bold mb-4">
            Exportar Leads {isCustomDateRange ? "del Rango Seleccionado" : "del Mes Actual"}
          </h1>
          
          <form onSubmit={handleExport} className="search-form space-y-4">
            {renderCampaignSelector()}

            <div className="flex space-x-2">
  <button 
    type="button" 
    onClick={previewLeads}
    className="button bg-gray-500 hover:bg-gray-600 text-white p-2 rounded"
  >
    {isLoadingPreview ? "Cargando..." : "Cargar Leads"}
  </button>

  <button 
    type="submit" 
    className="button bg-gray-500 hover:bg-gray-600 text-white p-2 rounded"
    disabled={isLoading || !canExport}
  >
    {isLoading ? "Exportando..." : "Exportar a CSV"}
  </button>
</div>
          </form>

          <div className="date-range-picker mt-4">
            <DateRange
              editableDateInputs={true}
              onChange={handleDateRangeChange}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              maxDate={today}
              color={"#07ea93"}
              onRangeFocusChange={() => setIsFocusDate(true)}
              className={`date-range-picker${isFocusDate ? " focusDate" : ""}`}
            />
            {isCustomDateRange && (
              <div className="control-buttons mt-2">
                <button 
                  className="button bg-gray-500 hover:bg-gray-600 text-white p-2 rounded"
                  onClick={resetToCurrentMonth}
                >
                  Volver al Mes Actual
                </button>
              </div>
            )}
          </div>

          {exportError && (
            <div className="error-message bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4" role="alert">
              <span className="block sm:inline">{exportError}</span>
            </div>
          )}

          {previewData && !hasExported && !exportError && (
            <div className="preview-metrics mt-4 bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded">
              <p className="font-bold">
                Leads disponibles para exportar: {previewData.totalLeads.toLocaleString()}
              </p>
              <p className="text-sm mt-2">
                Estimación de registros válidos: {previewData.estimatedValid.toLocaleString()} ({previewData.validRate}%) | 
                Posibles registros omitidos: {previewData.estimatedSkipped.toLocaleString()}
              </p>
              <p className="text-xs mt-1 text-blue-600">
                Basado en muestra de {previewData.sampleSize} registros 
                {previewData.confidence === 'high' && ' - Alta confiabilidad'}
                {previewData.confidence === 'medium' && '- Confiabilidad media'}
                {previewData.confidence === 'low' && '- Baja confiabilidad'}
              </p>
            </div>
          )}

          {hasExported && !exportError && (
            <div className="export-metrics mt-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded">
              <p className="font-bold">
                Total de leads exportados: {totalLeads.toLocaleString()}
              </p>
              {totalProcessed > 0 && (
                <p className="text-sm mt-2">
                  Registros procesados: {totalProcessed.toLocaleString()} | 
                  Registros omitidos: {totalSkipped.toLocaleString()}
                </p>
              )}
            </div>
          )}

          {(isLoading || isLoadingPreview) && (
            <div className="loader-table mt-4 text-center">
              <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
              <h3 className="mt-2">
                {isLoading ? "Exportando leads..." : "Cargando leads..."}
              </h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExportLeads;