import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const checkAuth = () => {
    try {
      const tokenData = localStorage.getItem("tokenData");
      
      if (!tokenData) {
        return false;
      }

      const { expires_at } = JSON.parse(tokenData);
      
      // Verificar si el token ha expirado
      if (expires_at < Date.now() / 1000) {
        // Limpiar token expirado
        localStorage.removeItem("tokenData");
        return false;
      }

      return true;
    } catch (error) {
      console.error("Error validating authentication:", error);
      return false;
    }
  };

  return checkAuth() ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;