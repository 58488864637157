import React, { useEffect, useCallback, useMemo, useReducer, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { DateRange } from "react-date-range";
import { startOfMonth, endOfMonth, isAfter, isBefore, startOfDay } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import LeadDetailView from "./LeadDetailView";
import Sidebar from "../components/Sidebar";
import LineChart from "../components/LineChart";

// Estado inicial consolidado
const initialState = {
  leads: [],
  currentPage: 1,
  totalPages: 0,
  isLoading: false,
  selectedLead: null,
  totalLeads: 0,
  pendingLeads: 0,
  isSidebarOpen: true,
  leadsByDay: [],
  monthlyLeadsData: [],
  error: null,
  isFocusDate: false,
  sortField: "fecha",
  sortDirection: "desc",
  averageLeads: 0,
  dateRange: [{
    startDate: startOfMonth(new Date()),
    endDate: isBefore(endOfMonth(new Date()), new Date()) ? endOfMonth(new Date()) : new Date(),
    key: "selection"
  }],
  isCustomDateRange: false
};

function campaignReducer(state, action) {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload, isLoading: false };
    case 'SET_DATA':
      return { ...state, ...action.payload, isLoading: false, error: null };
    case 'SET_MONTHLY_DATA':
      return { ...state, monthlyLeadsData: action.payload };
    case 'SET_DATE_RANGE':
      return { 
        ...state, 
        dateRange: action.payload.range,
        isCustomDateRange: action.payload.isCustom,
        currentPage: 1
      };
    case 'SET_SORT':
      return {
        ...state,
        sortField: action.payload.field,
        sortDirection: action.payload.direction,
        currentPage: 1
      };
    case 'SET_PAGE':
      return { ...state, currentPage: action.payload };
    case 'TOGGLE_SIDEBAR':
      return { ...state, isSidebarOpen: !state.isSidebarOpen };
    case 'SET_FOCUS_DATE':
      return { ...state, isFocusDate: action.payload };
    default:
      return state;
  }
}

const CampaignLeads = () => {
  const [state, dispatch] = useReducer(campaignReducer, initialState);
  const { campaignName } = useParams();
  const navigate = useNavigate();
  const fetchTimeoutRef = useRef(null);

  // API calls optimizados
  const fetchLeads = useCallback(async () => {
    if (state.isLoading) return;
    
    dispatch({ type: 'SET_LOADING', payload: true });
    
    try {
      const startDate = state.dateRange[0].startDate.toISOString().split("T")[0];
      const endDate = state.dateRange[0].endDate.toISOString().split("T")[0];
      
      const response = await axios.get(
        `/api/leads/get_leads.php?api_key=mangoCia_2024_APIKEY_8f6a7d9e4c3b2a1&page=${
          state.currentPage
        }&campaign=${encodeURIComponent(campaignName)}&startDate=${startDate}&endDate=${endDate}&sortField=${
          state.sortField
        }&sortDirection=${state.sortDirection}&t=${new Date().getTime()}`
      );

      const leadsByDayProcessed = response.data.leadsByDay.map((item) => ({
        day: new Date(item.day).getDate(),
        received_count: parseInt(item.received_count),
        pending_count: parseInt(item.pending_count),
      }));

      // Calculate average leads
      const startDateObj = new Date(state.dateRange[0].startDate);
      const endDateObj = new Date(state.dateRange[0].endDate);
      const daysDiff = Math.ceil((endDateObj - startDateObj) / (1000 * 60 * 60 * 24)) + 1;
      const receivedLeads = response.data.totalLeads - response.data.pendingLeads;
      const average = receivedLeads / daysDiff;

      dispatch({
        type: 'SET_DATA',
        payload: {
          leads: response.data.leads,
          totalPages: response.data.totalPages,
          totalLeads: response.data.totalLeads,
          pendingLeads: response.data.pendingLeads,
          leadsByDay: leadsByDayProcessed,
          averageLeads: average
        }
      });
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: "Error al cargar los datos. Por favor intente nuevamente."
      });
    }
  }, [state.currentPage, state.dateRange, state.sortField, state.sortDirection, campaignName]);

  const fetchMonthlyLeads = useCallback(async () => {
    try {
      const response = await axios.get(
        `/api/leads/get_monthly_leads.php?api_key=mangoCia_2024_APIKEY_8f6a7d9e4c3b2a1&campaign=${
          encodeURIComponent(campaignName)
        }`
      );
      dispatch({ type: 'SET_MONTHLY_DATA', payload: response.data.monthlyData });
    } catch (error) {
      console.error("Error fetching monthly leads:", error);
    }
  }, [campaignName]);

  // Effects optimizados
  useEffect(() => {
    if (fetchTimeoutRef.current) {
      clearTimeout(fetchTimeoutRef.current);
    }
    
    fetchTimeoutRef.current = setTimeout(() => {
      fetchLeads();
      fetchMonthlyLeads();
    }, 300);

    return () => {
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }
    };
  }, [fetchLeads, fetchMonthlyLeads]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dateRangePicker = document.querySelector(".date-range-picker");
      if (dateRangePicker && !dateRangePicker.contains(event.target)) {
        dispatch({ type: 'SET_FOCUS_DATE', payload: false });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Event handlers
  const handleDateRangeChange = (item) => {
    const newRange = item.selection;
    if (isAfter(newRange.endDate, new Date())) {
      newRange.endDate = new Date();
    }
    dispatch({
      type: 'SET_DATE_RANGE',
      payload: { range: [newRange], isCustom: true }
    });
  };

  const resetToCurrentMonth = () => {
    const today = new Date();
    const currentMonthRange = {
      startDate: startOfMonth(today),
      endDate: isBefore(endOfMonth(today), today) ? endOfMonth(today) : today,
      key: "selection",
    };
    dispatch({
      type: 'SET_DATE_RANGE',
      payload: { range: [currentMonthRange], isCustom: false }
    });
  };

  const handleSort = (field) => {
    const newDirection = field === state.sortField && state.sortDirection === "asc" ? "desc" : "asc";
    dispatch({
      type: 'SET_SORT',
      payload: { field, direction: newDirection }
    });
  };

  // Helper functions
  const formatJSONForTooltip = (jsonString) => {
    try {
      const parsed = typeof jsonString === 'string' ? JSON.parse(jsonString) : jsonString;
      return JSON.stringify(parsed, null, 2);
    } catch (e) {
      return jsonString || 'N/A';
    }
  };

  const truncateText = (text, maxLength) => {
    if (!text || text.length <= maxLength) return text || "N/A";
    return text.substring(0, maxLength) + "...";
  };

  const exportToCSV = useCallback(() => {
    const csvRows = [];
    const headers = ["ID", "Fecha", "Data", "Respuesta CRM", "Status"];
    csvRows.push(headers.join(","));

    state.leads.forEach((lead) => {
      const formatJSON = (json) => {
        try {
          const obj = typeof json === "string" ? JSON.parse(json) : json;
          return JSON.stringify(obj).replace(/"/g, '""');
        } catch (e) {
          return String(json).replace(/"/g, '""');
        }
      };

      const row = [
        lead.id,
        lead.fecha ? new Date(lead.fecha).toLocaleString() : "N/A",
        `"${formatJSON(lead.recibido)}"`,
        `"${formatJSON(lead.respuesta_enviado)}"`,
        lead.status === 1 ? "Recibido" : "Pendiente",
      ];
      csvRows.push(row.join(","));
    });

    const csvContent = `data:text/csv;charset=utf-8,${csvRows.join("\n")}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `leads_${campaignName}_${new Date().toISOString().split("T")[0]}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [state.leads, campaignName]);

  // Memoized values
  const chartData = useMemo(
    () => ({
      labels: state.leadsByDay.map((item) => item.day),
      datasets: [
        {
          label: "Recibidos por Día",
          data: state.leadsByDay.map((item) => item.received_count),
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(7, 234, 147, 1)",
          pointBackgroundColor: "rgba(7, 234, 147, 1)",
          pointBorderColor: "#07ea93",
          borderWidth: 2,
          tension: 0.4,
          fill: false,
        },
        {
          label: "Pendientes por Día",
          data: state.leadsByDay.map((item) => item.pending_count),
          backgroundColor: "rgba(192, 75, 75, 0.2)",
          borderColor: "rgba(234, 7, 7, 1)",
          pointBackgroundColor: "rgba(234, 7, 7, 1)",
          pointBorderColor: "#ea0707",
          borderWidth: 2,
          tension: 0.4,
          fill: false,
        },
      ],
    }),
    [state.leadsByDay]
  );

  const monthlyChartData = useMemo(() => ({
    labels: state.monthlyLeadsData.map(item => item.month),
    datasets: [
      {
        label: 'Recibidos por Mes',
        data: state.monthlyLeadsData.map(item => item.receivedLeads),
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(7, 234, 147, 1)",
        pointBackgroundColor: "rgba(7, 234, 147, 1)",
        pointBorderColor: "#07ea93",
        borderWidth: 2,
        tension: 0.4,
        fill: false,
      },
      {
        label: 'Pendientes por Mes',
        data: state.monthlyLeadsData.map(item => item.pendingLeads),
        backgroundColor: "rgba(192, 75, 75, 0.2)",
        borderColor: "rgba(234, 7, 7, 1)",
        pointBackgroundColor: "rgba(234, 7, 7, 1)",
        pointBorderColor: "#ea0707",
        borderWidth: 2,
        tension: 0.4,
        fill: false,
      }
    ],
  }), [state.monthlyLeadsData]);

  const options = useMemo(
    () => ({
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    }),
    []
  );

  // Render
  if (state.selectedLead) {
    return (
      <LeadDetailView 
        lead={state.selectedLead} 
        onBack={() => dispatch({ type: 'SET_DATA', payload: { selectedLead: null } })} 
      />
    );
  }

  return (
    <div className="app-container">
      <Sidebar 
        isOpen={state.isSidebarOpen} 
        toggleSidebar={() => dispatch({ type: 'TOGGLE_SIDEBAR' })} 
      />
      <div className={`main-content ${state.isSidebarOpen ? "sidebar-open" : ""}`}>
        <div className="header-table">
          <h1>Leads de la Campaña: {decodeURIComponent(campaignName)}</h1>
          <div className="date-range-picker">
            <DateRange
              editableDateInputs={true}
              onChange={handleDateRangeChange}
              moveRangeOnFirstSelection={false}
              ranges={state.dateRange}
              maxDate={new Date()}
              color={"#07ea93"}
              onRangeFocusChange={() => 
                dispatch({ type: 'SET_FOCUS_DATE', payload: true })
              }
              className={
                "date-range-picker" + (state.isFocusDate ? " focusDate" : "")
              }
            />
          </div>
          <div className="control-buttons">
            {state.isCustomDateRange && (
              <button className="button" onClick={resetToCurrentMonth}>
                Ver Mes Actual
              </button>
            )}
            <button
              className="button"
              onClick={() => fetchLeads()}
              disabled={state.isLoading}
            >
              {state.isLoading ? "Actualizando..." : "Actualizar Datos"}
            </button>
            <button className="button" onClick={() => navigate("/dashboard")}>
              Volver al Dashboard
            </button>
            <button className="button" onClick={exportToCSV}>
              Exportar
            </button>
          </div>
          <p>
            <strong>
              Recibidos {state.totalLeads.toLocaleString()} / Pendientes {state.pendingLeads.toLocaleString()} / AVG {Math.round(state.averageLeads)}
            </strong>
          </p>
          <div className="chart-container">
            {state.leadsByDay.length > 0 ? (
              <LineChart data={chartData} options={{
                ...options,
                plugins: {
                  title: {
                    display: true,
                    text: 'Leads por día',
                  },
                },
              }} />
            ) : (
              <p>No hay datos disponibles para el gráfico diario</p>
            )}
          </div>
          <div className="chart-container">
            {state.monthlyLeadsData.length > 0 ? (
              <LineChart data={monthlyChartData} options={{
                ...options,
                plugins: {
                  title: {
                    display: true,
                    text: 'Leads Mensuales (Últimos 6 meses)',
                  },
                },
              }} />
            ) : (
              <p>No hay datos mensuales disponibles</p>
            )}
          </div>
        </div>
        <div className={!state.isLoading ? "table-leads" : "table-leads over-hidden"}>
          {state.isLoading && (
            <div className="loader-table">
              <h3>Cargando...</h3>
            </div>
          )}
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>
                  Fecha
                  <a href="#" onClick={() => handleSort("fecha")}>
                    {state.sortField === "fecha"
                      ? state.sortDirection === "asc"
                        ? "▲"
                        : "▼"
                      : "▲▼"}
                  </a>
                </th>
                <th>Data</th>
                <th>Respuesta CRM</th>
                <th>
                  Status
                  <a href="#" onClick={() => handleSort("status")}>
                    {state.sortField === "status"
                      ? state.sortDirection === "asc"
                        ? "▲"
                        : "▼"
                      : "▲▼"}
                  </a>
                </th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {state.leads.map((lead) => (
                <tr key={lead.id}>
                  <td>{lead.id}</td>
                  <td>
                    {lead.fecha ? new Date(lead.fecha).toLocaleString() : "N/A"}
                  </td>
                  <td title={formatJSONForTooltip(lead.recibido)}>
                    {truncateText(lead.recibido, 50)}
                  </td>
                  <td title={formatJSONForTooltip(lead.respuesta_enviado)}>
                    {truncateText(lead.respuesta_enviado, 30)}
                  </td>
                  <td>
                    <span
                      className={`status-badge ${
                        lead.status === 1 ? "status-success" : "status-pending"
                      }`}
                    >
                      {lead.status === 1 ? "Recibido" : "Pendiente"}
                    </span>
                  </td>
                  <td>
                    <button
                      className="button"
                      onClick={() => dispatch({ 
                        type: 'SET_DATA', 
                        payload: { selectedLead: lead } 
                      })}
                    >
                      Ver
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <button
            onClick={() => dispatch({ 
              type: 'SET_PAGE', 
              payload: Math.max(state.currentPage - 1, 1) 
            })}
            disabled={state.currentPage === 1 || state.isLoading}
          >
            Anterior
          </button>
          <span>
            Página {state.currentPage} de {state.totalPages}
          </span>
          <button
            onClick={() => dispatch({ 
              type: 'SET_PAGE', 
              payload: Math.min(state.currentPage + 1, state.totalPages) 
            })}
            disabled={state.currentPage === state.totalPages || state.isLoading}
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  );
};

export default CampaignLeads;