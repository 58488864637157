import React, {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useReducer
} from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { DateRange } from "react-date-range";
import { startOfMonth, endOfMonth, isAfter, isBefore, startOfDay } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import LeadDetailView from "./LeadDetailView";
import Sidebar from "../components/Sidebar";
import LineChart from "../components/LineChart";

// Estado inicial consolidado
const initialState = {
  leads: [],
  currentPage: 1,
  totalPages: 0,
  isLoading: false,
  selectedLead: null,
  totalLeads: 0,
  pendingLeads: 0,
  isSidebarOpen: true,
  leadsByDay: [],
  error: null,
  isFocusDate: false,
  dateRange: [{
    startDate: startOfMonth(new Date()),
    endDate: isBefore(endOfMonth(new Date()), new Date()) ? endOfMonth(new Date()) : new Date(),
    key: "selection"
  }],
  isCustomDateRange: false,
  sortField: "fecha",
  sortDirection: "desc"
};

// Reducer para manejar todos los estados
function dashboardReducer(state, action) {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_DATA':
      return { ...state, ...action.payload };
    case 'SET_PAGE':
      return { ...state, currentPage: action.payload };
    case 'SET_SORT':
      return { 
        ...state, 
        sortField: action.payload.field,
        sortDirection: action.payload.direction,
        currentPage: 1
      };
    case 'SET_DATE_RANGE':
      return {
        ...state,
        dateRange: action.payload.range,
        isCustomDateRange: action.payload.isCustom,
        currentPage: 1
      };
    case 'TOGGLE_SIDEBAR':
      return { ...state, isSidebarOpen: !state.isSidebarOpen };
    case 'SET_FOCUS_DATE':
      return { ...state, isFocusDate: action.payload };
    default:
      return state;
  }
}

const Dashboard = () => {
  const [state, dispatch] = useReducer(dashboardReducer, initialState);
  const navigate = useNavigate();
  const tableLeadsRef = useRef(null);
  const fetchTimeoutRef = useRef(null);

  const fetchLeads = useCallback(async () => {
    if (state.isLoading) return;

    dispatch({ type: 'SET_LOADING', payload: true });
    
    try {
      const startDate = state.dateRange[0].startDate.toISOString().split("T")[0];
      const endDate = state.dateRange[0].endDate.toISOString().split("T")[0];
      
      const response = await axios.get(
        `/api/leads/get_leads.php?api_key=mangoCia_2024_APIKEY_8f6a7d9e4c3b2a1&page=${
          state.currentPage
        }&startDate=${startDate}&endDate=${endDate}&sortField=${
          state.sortField
        }&sortDirection=${state.sortDirection}&t=${new Date().getTime()}`
      );

      dispatch({
        type: 'SET_DATA',
        payload: {
          leads: response.data.leads,
          totalPages: response.data.totalPages,
          totalLeads: response.data.totalLeads,
          pendingLeads: response.data.pendingLeads,
          leadsByDay: response.data.leadsByDay,
          isLoading: false,
          error: null
        }
      });
    } catch (error) {
      dispatch({
        type: 'SET_DATA',
        payload: {
          isLoading: false,
          error: "Hubo un problema al cargar los datos. Por favor, intente de nuevo."
        }
      });
    }
  }, [state.currentPage, state.dateRange, state.sortField, state.sortDirection]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dateRangePicker = document.querySelector(".date-range-picker");
      if (dateRangePicker && !dateRangePicker.contains(event.target)) {
        dispatch({ type: 'SET_FOCUS_DATE', payload: false });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (fetchTimeoutRef.current) {
      clearTimeout(fetchTimeoutRef.current);
    }
    
    fetchTimeoutRef.current = setTimeout(() => {
      fetchLeads();
    }, 300);

    return () => {
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }
    };
  }, [fetchLeads]);

  useEffect(() => {
    if (tableLeadsRef.current) {
      tableLeadsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [state.currentPage]);

  const handleDateRangeChange = (item) => {
    const newRange = item.selection;
    if (isAfter(newRange.endDate, new Date())) {
      newRange.endDate = new Date();
    }
    dispatch({
      type: 'SET_DATE_RANGE',
      payload: {
        range: [newRange],
        isCustom: true
      }
    });
  };

  const resetToCurrentMonth = () => {
    const today = new Date();
    const currentMonthRange = {
      startDate: startOfMonth(today),
      endDate: isBefore(endOfMonth(today), today) ? endOfMonth(today) : today,
      key: "selection",
    };
    dispatch({
      type: 'SET_DATE_RANGE',
      payload: {
        range: [currentMonthRange],
        isCustom: false
      }
    });
  };

  const handleSort = (field) => {
    const newDirection =
      field === state.sortField && state.sortDirection === "asc" ? "desc" : "asc";
    dispatch({
      type: 'SET_SORT',
      payload: { field, direction: newDirection }
    });
  };

  const chartData = useMemo(
    () => ({
      labels: state.leadsByDay.map((item) => new Date(item.day).getDate()),
      datasets: [
        {
          label: "Ingresados",
          data: state.leadsByDay.map((item) => item.received_count),
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(7, 234, 147, 1)",
          pointBackgroundColor: "rgba(7, 234, 147, 1)",
          pointBorderColor: "#07ea93",
          borderWidth: 2,
          tension: 0.4,
          fill: false,
        },
        {
          label: "Pendientes",
          data: state.leadsByDay.map((item) => item.pending_count),
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgba(255, 99, 132, 1)",
          pointBackgroundColor: "rgba(255, 99, 132, 1)",
          pointBorderColor: "#FF6384",
          borderWidth: 2,
          tension: 0.4,
          fill: false,
        },
      ],
    }),
    [state.leadsByDay]
  );

  const options = useMemo(
    () => ({
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    }),
    []
  );

  if (state.selectedLead) {
    return (
      <LeadDetailView 
        lead={state.selectedLead} 
        onBack={() => dispatch({ type: 'SET_DATA', payload: { selectedLead: null } })} 
      />
    );
  }

  return (
    <div className="app-container">
      <Sidebar 
        isOpen={state.isSidebarOpen} 
        toggleSidebar={() => dispatch({ type: 'TOGGLE_SIDEBAR' })} 
      />
      <div className={`main-content ${state.isSidebarOpen ? "sidebar-open" : ""}`}>
        <div className="header-table">
          <h1>
            Leads del {state.isCustomDateRange ? "Rango Seleccionado" : "Mes Actual"}
          </h1>
          <div className="date-range-picker">
            <DateRange
              editableDateInputs={true}
              onChange={handleDateRangeChange}
              moveRangeOnFirstSelection={false}
              ranges={state.dateRange}
              maxDate={new Date()}
              color={"#07ea93"}
              onRangeFocusChange={(focused) => 
                dispatch({ type: 'SET_FOCUS_DATE', payload: focused })
              }
              className={
                "date-range-picker" + (state.isFocusDate ? " focusDate" : "")
              }
            />
          </div>
          <div className="control-buttons">
            {state.isCustomDateRange && (
              <button className="button" onClick={resetToCurrentMonth}>
                Volver al Mes Actual
              </button>
            )}
            <button
              className="button"
              onClick={() => fetchLeads()}
              disabled={state.isLoading}
            >
              {state.isLoading ? "Actualizando..." : "Actualizar Datos"}
            </button>
          </div>
          <p>
            <strong>
              Total de leads del período: {state.totalLeads.toLocaleString()} (
              {state.pendingLeads.toLocaleString()} Pendientes)
            </strong>
          </p>
          {state.error && <div className="error-message">{state.error}</div>}
          <div className="chart-container">
            {state.leadsByDay.length > 0 ? (
              <LineChart data={chartData} options={options} />
            ) : (
              <p>No hay datos disponibles para el gráfico</p>
            )}
          </div>
        </div>
        <div
          className={!state.isLoading ? "table-leads" : "table-leads over-hidden"}
          ref={tableLeadsRef}
        >
          {state.isLoading && (
            <div className="loader-table">
              <h3>Cargando...</h3>
            </div>
          )}
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Campaña</th>
                <th>
                  Fecha
                  <a href="#" onClick={() => handleSort("fecha")}>
                    {state.sortField === "fecha"
                      ? state.sortDirection === "asc"
                        ? "▲"
                        : "▼"
                      : "▲▼"}
                  </a>
                </th>
                <th>
                  Status
                  <a href="#" onClick={() => handleSort("status")}>
                    {state.sortField === "status"
                      ? state.sortDirection === "asc"
                        ? "▲"
                        : "▼"
                      : "▲▼"}
                  </a>
                </th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {state.leads.map((lead) => (
                <tr key={lead.id}>
                  <td>{lead.id}</td>
                  <td>
                    <Link to={`/campaign/${encodeURIComponent(lead.campana)}`}>
                      {lead.campana}
                    </Link>
                  </td>
                  <td>{new Date(lead.fecha).toLocaleString()}</td>
                  <td>
                    <span
                      className={`status-badge ${
                        lead.status === 1 ? "status-success" : "status-pending"
                      }`}
                    >
                      {lead.status === 1 ? "Recibido" : "Pendiente"}
                    </span>
                  </td>
                  <td>
                    <button
                      className="button"
                      onClick={() => dispatch({ 
                        type: 'SET_DATA', 
                        payload: { selectedLead: lead } 
                      })}
                    >
                      Ver
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <button
            onClick={() => dispatch({ 
              type: 'SET_PAGE', 
              payload: Math.max(state.currentPage - 1, 1) 
            })}
            disabled={state.currentPage === 1 || state.isLoading}
          >
            Anterior
          </button>
          <span>
            Página {state.currentPage} de {state.totalPages}
          </span>
          <button
            onClick={() => dispatch({ 
              type: 'SET_PAGE', 
              payload: Math.min(state.currentPage + 1, state.totalPages) 
            })}
            disabled={state.currentPage === state.totalPages || state.isLoading}
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;