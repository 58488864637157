import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../services/api";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkingAuth, setCheckingAuth] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar token y expiración
    try {
      const tokenData = localStorage.getItem("tokenData");
      if (tokenData) {
        const { expires_at } = JSON.parse(tokenData);
        if (expires_at > Date.now() / 1000) {
          navigate("/dashboard");
          return;
        }
        localStorage.removeItem("tokenData");
      }
    } catch (error) {
      localStorage.removeItem("tokenData");
    } finally {
      setCheckingAuth(false);
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    
    setLoading(true);
    setError("");

    try {
      const response = await login(email, password);

      if (response.data.success) {
        const tokenData = {
          token: response.data.token,
          expires_at: response.data.expires_at,
          expires_in: response.data.expires_in
        };
        localStorage.setItem("tokenData", JSON.stringify(tokenData));
        navigate("/dashboard");
      } else {
        setError(response.data.message || "Error de autenticación");
      }
    } catch (err) {
      if (err.response?.data?.message?.includes("Too many attempts")) {
        setError("Demasiados intentos. Por favor, intente más tarde.");
      } else {
        setError(err.response?.data?.message || "Error al intentar conectar con el servidor");
      }
    } finally {
      setLoading(false);
    }
  };

  if (checkingAuth) {
    return (
      <div className="login">
        <div className="container flex flexCol relative alignCenter">
          <div className="loginDiv" style={{ textAlign: 'center' }}>
            <h2>MangoLeads</h2>
            <p>Verificando sesión...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="login">
      <div className="container flex flexCol relative alignCenter">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 664 692"
          colorInterpolation="sRGB"
        >
          <path
            fill={"#fff"}
            d="M63.16 1.362c-6.684 23.344 17.037 101.946 17.037 101.946s-9.423-30.003-34.501-27.39c-18.947 1.985-31.838 15.429-34.753 34.25-4.875 31.461 43.07 57.67 73.2 22.239 26.485-33.245 18.343-100.112 8.392-121.32C86.731-1.302 63.487.055 63.185 1.337l-.025.025zM318.843 691.465c-39.98 0-97.851-15.152-176.352-70.158C54.214 559.44 20.819 485.387 8.33 434.15c-12.79-52.519-6.91-91.895-5.905-97.825 1.784-25.33 7.865-50.056 18.067-73.476 23.847-54.78 67.596-96.996 123.205-118.883 55.609-21.887 116.395-20.781 171.175 3.041 41.889 18.218 76.717 48.246 100.765 86.793 23.571 37.794 35.18 81.09 33.597 125.216-2.035 56.438-31.938 136.497-43.296 162.581-7.664 17.59-3.619 30.732 1.106 38.673 33.094 55.458-1.106 97.046-18.018 109.208-4.774 4.297-27.063 21.962-70.183 21.962v.025z"
          ></path>
          <path
            fill={"#fff"}
            d="M336.86 497.222c-2.94 0-5.805-.05-8.594-.126-54.077-1.658-90.488-17.816-95.891-20.329-23.922-8.468-46.136-20.907-66.012-36.964-46.463-37.567-75.511-90.965-81.819-150.394-6.282-59.428 10.931-117.726 48.498-164.189 28.722-35.532 66.918-61.112 110.49-74.029 42.694-12.664 87.523-12.338 129.638.93 53.851 16.962 123.079 67.093 145.193 84.985 14.926 12.061 28.671 11.659 37.567 9.222 62.243-17.138 93.251 26.887 100.488 46.437 5.529 11.082 41.588 97.926-112.073 224.825-78.727 65.007-154.892 79.657-207.511 79.657l.026-.025z"
          ></path>
          <path
            fill={"#fff"}
            d="M338.367 581.227c-57.343 0-102.926-14.324-135.141-29.476-51.588-24.299-79.933-55.182-83.954-59.756-19.525-18.168-35.733-39.451-48.171-63.273-29.074-55.635-34.728-119.285-15.932-179.166C73.966 189.649 114.95 140.674 170.61 111.6c42.542-22.213 90.085-30.908 137.452-25.128 46.438 5.679 89.533 24.751 124.562 55.157 53.197 46.186 104.032 141.9 104.51 142.855 9.473 18.143 23.143 23.621 32.918 25.003 66.917 9.499 78.3 64.681 77.094 86.392.679 12.64-.729 111.545-202.158 169.642-39 11.233-74.632 15.706-106.621 15.706zm-187.458-91.669c12.765 11.182 34.124 27.415 63.5 41.11 67.319 31.36 142.679 35.381 223.97 11.961 100.313-28.923 145.318-67.419 165.42-94.634 21.083-28.546 19.601-50.056 19.525-50.96l-.1-1.056.1-.954c.051-.528 3.342-53.424-56.614-61.917-12.539-1.784-35.783-9.021-50.684-37.567-.478-.905-49.126-92.599-99.006-135.895-31.436-27.289-70.109-44.402-111.822-49.503-42.568-5.202-85.286 2.613-123.532 22.59-50.006 26.109-86.844 70.134-103.73 123.959-16.887 53.825-11.81 110.993 14.323 160.998 11.283 21.611 26.008 40.884 43.774 57.293l15.554 14.374-.653.176-.025.025z"
          ></path>
        </svg>
        <div className="loginDiv">
          <h2>Login</h2>
          {error && <p className="error">{error}</p>}
          <form className="form" onSubmit={handleSubmit}>
            <input
              type="email"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
              required
            />
            <input
              type="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
              required
            />
            <button className="button" type="submit" disabled={loading}>
              {loading ? "Cargando..." : "Login"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;