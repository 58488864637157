import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import LeadList from "./pages/Leadlist";
import Campaigns from "./pages/Campaigns";
import CampaignLeads from "./pages/CampaignLeads";
import SearchLeads from "./pages/SearchLeads.js";
import ExportLeads from "./pages/ExportLeads";
import PrivateRoute from "./components/private-route-component.js";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/leads" element={<LeadList />} />
            <Route path="/campaigns" element={<Campaigns />} />
            <Route path="/campaign/:campaignName" element={<CampaignLeads />} />
            <Route path="/search" element={<SearchLeads />} />
            <Route path="/export" element={<ExportLeads />} />
          </Route>
          <Route path="*" element={<Login />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
