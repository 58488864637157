import axios from "axios";

const api = axios.create({
  baseURL: "/api",
});

export const login = (email, password) => {
  console.log("Sending login request:", { email, password });
  return api.post("/auth/login.php", { username: email, password });
};

export const getLeads = (params) => {
  return api.get("/leads/get_leads.php", { params });
};

export const getCampaigns = () => {
  return api.get("/campaigns/get_campaigns.php");
};

export const getLeadsSummary = () => {
  return api.get("/dashboard/leads_summary.php");
};

export const getCampaignsSummary = () => {
  return api.get("/dashboard/campaigns_summary.php");
};

export const exportLeads = (filters) => {
  return api.get("/leads/export_leads_contacts.php", { 
    params: filters,
    responseType: 'blob'
  });
};

export default api;