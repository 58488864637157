import React from "react";

const LeadDetailView = ({ lead, onBack }) => {
  const renderObject = (obj) => {
    return (
      <ul>
        {Object.entries(obj).map(([key, value]) => (
          <li key={key}>
            <strong>{key}:</strong>{" "}
            {typeof value === "object" ? renderObject(value) : value}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="lead-detail-view">
      <h2>Detalles del Lead</h2>
      <div className="table-leads">
        <table>
          <tbody>
            {Object.entries(lead).map(([key, value]) => (
              <tr key={key}>
                <th>{key}</th>
                <td>
                  {typeof value === "object"
                    ? renderObject(value)
                    : key === "fecha"
                    ? new Date(value).toLocaleString()
                    : value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button className="button" onClick={onBack}>
        Regresar al Dashboard
      </button>
    </div>
  );
};

export default LeadDetailView;
